// Tous les fichiers qui sont inclus ici DOIVENT ABSOLUMENT ÊTRE
// EXPLICITEMENT BLACKLISTES directement dans le code du profile.

// Drupal core
// import "../../www/sites/all/modules/contrib/jquery_update/replace/jquery/1.10/jquery.min.js";
import "../../www/misc/jquery.once.js";
// import "../../www/misc/drupal.js";
import "../../www/misc/ajax.js";
import "../../www/misc/autocomplete.js";
import "../../www/misc/progress.js";
import "../../www/sites/all/modules/contrib/jquery_update/replace/jquery.form/3/jquery.form.min.js";

// Select2
import "../dist/select2/select2-4.0.2.full.js";
import "../dist/select2/i18n/fr.js";

// jQuery UI core
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.core.js";

// Datepicker
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.datepicker.js";
import "../../www/modules/locale/locale.datepicker.js";

// jQuery UI various components
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.widget.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.button.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.mouse.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.draggable.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.position.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.resizable.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.dialog.js";
import "../../www/profiles/saur/js/jquery/ui/jquery.ui.droppable.js";

// jQuery update module files
import "../../www/sites/all/modules/contrib/jquery_update/replace/ui/external/jquery.cookie.js";
import "../../www/sites/all/modules/contrib/jquery_update/js/jquery_update.js";

// Bootstrap!
import "./../common/bootstrap/js/affix.js";
import "./../common/bootstrap/js/alert.js";
import "./../common/bootstrap/js/button.js";
import "./../common/bootstrap/js/collapse.js";
import "./../common/bootstrap/js/dropdown.js";
import "./../common/bootstrap/js/modal.js";
// import "./bootstrap/js/tab.js";
import "./../common/bootstrap/js/tooltip.js";
import "./../common/bootstrap/js/popover.js";
// Bootstrap - jQuery.ui customisation.
import "../../www/sites/all/themes/composer/drupal-badm/js/jquery.dialog.js";

// Custom
import "../../www/sites/all/modules/composer/drupal-minidialog/minidialog.js";
import "../../www/sites/all/modules/composer/drupal-ucms/ucms_seo/js/privacy-settings.js";
import "../../www/sites/all/modules/composer/drupal-udate/udate.js";
import "../../www/sites/all/modules/composer/drupal-iresponsive/js/picturefill.min.js";

//Sad but true story, I failed in making babel import it itself.
//It does its job for custom code, it leaves node-modules alone.
import Promise from 'core-js/es6/promise';
if (!window.Promise) {
    window.Promise = Promise;
}

import React from "react";
import ReactDOM from "react-dom";
window.React = React;
window.ReactDOM = ReactDOM;
